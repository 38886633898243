<template>
  <div>
    <app-collapse
      class="collapse-group"
      accordion
      type="border"
      style="margin-bottom: 20px"
    >
      <app-collapse-item title="Filters ">
        <b-row>
          <b-col sm="12" md="12" lg="6">
            <b-input-group class="input-group-merge mb-1">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input placeholder="Search" v-model="filters.search" />
            </b-input-group>
          </b-col>
          <b-col sm="12" md="2" lg="2">
            <v-select
              class="mb-1"
              v-model="filters.country"
              :reduce="(c) => c.lbl_id"
              :options="$store.state.master.country"
              aria-placeholder="Country"
              placeholder="Country"
              label="lbl_name"
            />
          </b-col>
          <b-col sm="6" md="2" lg="2">
            <b-button
              variant="primary"
              class="w-100 mb-1"
              @click="onFiltersChange"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              >Search</b-button
            >
          </b-col>
          <b-col sm="6" md="2" lg="2">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="w-100 mb-1"
              @click="clearFilters"
              >Reset</b-button
            >
          </b-col>
        </b-row>
      </app-collapse-item>
    </app-collapse>

    <b-row class="mb-2 mr-1">
      <b-col class="d-flex align-items-end justify-content-end">
        <b-button-group class="mb-1">
          <b-button variant="outline-info" right @click="downloadCSV">
            Export Customers
          </b-button>
          <b-button
            v-if="$can('create', 'contact')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="gradient-primary"
            @click="$router.push('/contacts/form/new')"
            >NEW CUSTOMER</b-button
          >
        </b-button-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-badge variant="info"
          >Total : {{ this.pagination.totalRows }}</b-badge
        >
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-form-checkbox
          name="check-button"
          inline
          v-model="filters.deleted"
          @input="onFiltersChange"
        >
          <b><u>show deleted entries</u></b>
        </b-form-checkbox>
      </b-col>
    </b-row>

    <b-card no-body class="mb-0" v-if="$can('read', 'contact')">
      <b-table
        small
        hover
        bordered
        :items="contact_list"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No records found"
        @row-clicked="onRowClicked"
      >
        <template #cell(sr)="data">
          {{
            data.index +
            1 +
            (pagination.currentPage - 1) * pagination.rowsPerPage
          }}
        </template>
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{
                  name: 'apps-users-view',
                  params: { id: data.item.id },
                }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.role
            }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <feather-icon
            v-if="$can('update', 'contact')"
            icon="EditIcon"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            title="Edit"
            class="mr-1"
            @click="
              () => {
                $router.push(`/contacts/form/${data.item.vendor_id}`);
              }
            "
          />
          <feather-icon
            v-else-if="$can('read', 'contact')"
            icon="EyeIcon"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.v-primary
            variant="outline-primary"
            title="Edit"
            class="mr-1"
            @click="
              () => {
                $router.push(`/contacts/form/${data.item.vendor_id}`);
              }
            "
          />
          <feather-icon
            v-if="$can('create', 'contract')"
            :id="`contract-row-${data.item.vendor_id}-copy-icon`"
            icon="CopyIcon"
            size="16"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.v-primary
            variant="outline-primary"
            title="Copy"
            class="mr-1 cursor-pointer"
            @click.stop="
              $router.push(`/contacts/form/${data.item.vendor_id}?copy=1`)
            "
          />
          <feather-icon
            v-if="$can('delete', 'contact') && !data.item.deleted"
            :id="`contract-row-${data.item.contract_id}-delete-icon`"
            icon="TrashIcon"
            size="16"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.v-primary
            variant="outline-primary"
            title="Delete Customer"
            class="mr-1 cursor-pointer"
            @click="
              () => {
                showModal(
                  'Are you sure want to delete this customer?',
                  'delete'
                ).then((value) => {
                  if (value) deleteContact(data.item.vendor_id, 1);
                });
              }
            "
          />

          <!-- @click.stop="deleteContact(data.item.vendor_id, 1)" -->
          <feather-icon
            v-if="$can('update', 'contact')"
            icon="EyeIcon"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.v-primary
            variant="outline-primary"
            title="View"
            class="mr-1"
            @click="
              () => {
                $router.push(`/contacts/overview/${data.item.vendor_id}`);
              }
            "
          />
        </template>
      </b-table>
      <template #footer>
        <div>
          <b-row>
            <b-col
              cols="12"
              class="d-flex align-items-end justify-content-end justify-content-sm-center"
            >
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="pagination.totalRows"
                :per-page="pagination.rowsPerPage"
                @change="onChange"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  VBTooltip,
  BFormCheckbox,
  BButtonGroup,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import * as VendorServices from "@/apiServices/VendorServices";
import { labels } from "@/utils/constants";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import { downloadFromURL } from "@/utils/helpers";
import { TokenService } from "@/apiServices/storageService";
import UploadSheets from "@/views/components/UploadSheets.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import showModal from "../../components/ModalConfirmation";

export default {
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,
    vSelect,
    VBTooltip,
    ToastificationContentVue,
    BFormCheckbox,
    BButtonGroup,
    UploadSheets,
    BInputGroup,
    BInputGroupPrepend,
    AppCollapseItem,
    AppCollapse,
  },

  data() {
    return {
      showModalUpload: false,
      pagination: {
        currentPage: 1,
        totalRows: 1,
        rowsPerPage: 10,
        perPageOptions: [10, 25, 50, 100],
      },
      tableColumns: [
        { label: "SR", key: "sr", thStyle: { width: "6rem" } },
        { label: "Company Name", key: "company", sortable: true },
        { label: "Country", key: "country_name", thStyle: { width: "20rem" } },
        { key: "actions", thStyle: { width: "12.5rem" } },
      ],
      contact_list: [],

      filters: {
        search: "",
        country: "",
        deleted: false,
      },
    };
  },

  methods: {
    onRowClicked(item, index, event) {
      if (this.$can("update", "contact")) {
        this.$router.push(`/contacts/form/${item.vendor_id}`);
      }
    },
    downloadCSV() {
      try {
        const token = TokenService.getToken();

        if (!token) {
          alert("Error in getting token");
        }

        let urlStr = `${process.env.VUE_APP_BASEURL}/vendor/sheet/download?authorization=${token}`;

        downloadFromURL(urlStr, "Customers", "csv");
      } catch (error) {
        console.error(`Error in downloadCSV `, error);
      }
    },
    clearFilters() {
      this.filters = {
        search: "",
        country: "",
        deleted: false,
      };
      this.pagination = {
        currentPage: 1,
        totalRows: 1,
        rowsPerPage: 10,
        perPageOptions: [10, 25, 50, 100],
      };
      this.onChange();
    },
    onFiltersChange() {
      this.pagination.currentPage = 1;
      this.onChange();
    },
    showModal,

    onChange() {
      this.$nextTick(() => {
        this.getVendors();
        localStorage.setItem(
          "contact-SearchAndPagination",
          JSON.stringify({
            filters: this.filters,
            pagination: this.pagination,
          })
        );
      });
    },

    async getVendors() {
      try {
        const response = await VendorServices.getAllVendor({
          search: this.filters.search,
          country: this.filters.country,
          deleted: this.filters.deleted,
          limit: this.pagination.rowsPerPage,
          page: this.pagination.currentPage,
        });
        if (!response.data.status) {
          return this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Issue in fetching Data",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
        this.contact_list = response.data.data.data;
        if (this.pagination.currentPage == 1) {
          this.pagination.totalRows = response.data.data.pagination.total;
        }
      } catch (err) {
        console.error("Error => ", err.message);
      }
    },

    async deleteContact(vendor_id) {
      try {
        const response = await VendorServices.deleteVendor({ vendor_id });
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Success",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.onChange();
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Failed",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (error) {
        console.error("Error in delete contact ", error);
      }
    },
  },

  beforeMount() {
    this.$store.dispatch("master/setLabelMaster", { key: labels.country });

    const contactlocalStorageStr = localStorage.getItem(
      "contact-SearchAndPagination"
    );
    if (contactlocalStorageStr) {
      const obj = JSON.parse(contactlocalStorageStr);
      this.filters = { ...this.filters, ...obj.filters };
      this.pagination = { ...this.pagination, ...obj.pagination };
    }
    this.getVendors();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.per-page-selector {
  width: 90px;
}
</style>
